import React from 'react';
import { RiDraftLine } from 'react-icons/ri';
import { useRouter } from 'next/router';
import { KeyedMutator } from 'swr';
import { contractTypeLabels, ContractWithOwners } from '~/models/contract';
import { ContractsContentsBasicCustomField } from '~/components/specifics/contracts/[id]/components/Contents/Basic/CustomField';
import { Button } from '@itandi/itandi-bb-ui';
import { PATH as CONTRACT_HISTORIES_PATH } from '~/pages/contracts/[id]/histories/index';
import { PATH as CONTRACT_EDIT_PATH } from '~/pages/contracts/[id]/edit';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ListCard } from '~/components/common/List/Card';
import { ListItem } from '~/components/common/List/Item';
import { TextWithUnitString } from '~/components/common/Text/WithUnit/String/index';
import { TextDate } from '~/components/common/Text/Date/index';
import Styles from './index.module.scss';
import { ContractAuthIdEditorModal } from '../Editor/Modal';

type Props = DeepReadonly<{
  contract: ContractWithOwners;
  contractMutate?: KeyedMutator<ContractWithOwners>;
}>;

export const ContractsContentsBasic: React.FC<Props> = ({
  contract,
  contractMutate
}: Props) => {
  const router = useRouter();
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  return (
    <div>
      <ContractAuthIdEditorModal
        authList={contract.authList}
        contract={contract}
        contractMutate={contractMutate}
        handleClose={handleClose}
        isOpen={isOpen}
      />
      <ListCard
        buttons={
          <>
            <Button
              height="small"
              onClick={(): void => {
                router.push(CONTRACT_HISTORIES_PATH(contract.id));
              }}
              variant="secondary"
            >
              契約履歴一覧
            </Button>
            <Button
              height="small"
              onClick={(): void => {
                router.push(CONTRACT_EDIT_PATH(contract.id));
              }}
              variant="secondary"
            >
              編集
            </Button>
            {!contract.archived && (
              <Button height="small" onClick={handleOpen} variant="secondary">
                支店変更
              </Button>
            )}
          </>
        }
        icon={<RiDraftLine />}
        title="契約情報"
      >
        <ListItem content={contract.storeName} title="支店名" />
        <ListItem
          content={
            <TextWithUnitString str={contract.originContract.contractKey} />
          }
          title="契約管理番号"
        />
        <ListItem
          content={contractTypeLabels[contract.contractType]}
          title="契約種別"
        />
        <ListItem
          content={
            <>
              <TextDate dateStr={contract.originContract.startDate} />
              <span className={Styles.TermSeparator}>-</span>
              <TextDate dateStr={contract.originContract.endDate} />
            </>
          }
          title="原契約期間"
        />
        <ListItem
          content={
            <>
              <TextDate dateStr={contract.startDate} />
              <span className={Styles.TermSeparator}>-</span>
              <TextDate dateStr={contract.endDate} />
            </>
          }
          title="契約期間"
        />
        <ListItem
          content={
            <TextWithUnitString
              str={String(contract.conditionFixedPeriod ?? '')}
              unit="ヶ月"
            />
          }
          title="条件固定期間"
        />
        <ListItem
          content={
            <TextWithUnitString
              str={String(contract.conditionUpdateCycle ?? '')}
              unit="ヶ月"
            />
          }
          title="条件変更周期"
        />
        <ListItem
          content={
            <TextWithUnitString
              str={String(contract.revisionPeriod ?? '')}
              unit="ヶ月"
            />
          }
          title="改定期間"
        />
        <ListItem
          content={
            <TextWithUnitString
              str={String(contract.lowerLimitOfRentRevisionRate ?? '')}
              unit="％"
            />
          }
          title="家賃改定割合の下限"
        />
        <ListItem
          content={
            <TextWithUnitString
              str={String(contract.lowerLimitOfRentRevisionRateDuration ?? '')}
              unit="年"
            />
          }
          title="家賃改定割合の下限の有効年数"
        />
        <ListItem
          content={
            (contract.remittanceDateType === 'specify_date' && '指定日') ||
            (contract.remittanceDateType === 'last_day' && '月末') ||
            '選択なし'
          }
          title="送金日"
        />
        {contract.remittanceDateType === 'specify_date' && (
          <ListItem
            content={
              <TextWithUnitString
                str={String(contract.remittanceDateSpecify ?? '')}
              />
            }
            title="送金指定日"
          />
        )}
        <ListItem
          content={<TextDate dateStr={contract.remittanceStartDate} />}
          title="送金開始日"
        />
        <ListItem
          content={
            <TextWithUnitString
              str={String(contract.numberOfManagedProperties ?? '')}
              unit="戸"
            />
          }
          title="管理戸数"
        />
        <ListItem
          content={
            <TextWithUnitString
              str={String(contract.managementReportingMonth ?? '')}
              unit="月"
            />
          }
          title="管理レポートの報告月"
        />
      </ListCard>
      <ContractsContentsBasicCustomField />
    </div>
  );
};
