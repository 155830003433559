import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { EditorMode } from '~/components/common/Contracts/Editor';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit, ContractForEditParams } from '~/models/contract';
import {
  create,
  update,
  renewal,
  editArchive
} from '~/utils/api/resources/contract';
import { useRouter } from 'next/router';
import { isSuccess } from '~/utils/api/apiHandler';
import { toast } from 'react-toastify';
import { PATH } from '~/pages/contracts/[id]';
import { convertRequestInvoices } from '~/models/invoice';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  editorMode: EditorMode;
}>;

const convertParams = (
  params: DeepReadonlyObject<ContractForEdit>
): DeepReadonly<ContractForEditParams> => ({
  ...params,
  invoices: convertRequestInvoices(params.invoices)
});

export const ContractsEditorFooter: React.FC<Props> = ({
  editorMode
}: Props) => {
  const router = useRouter();
  const { id } = router.query;
  const { getValues, handleSubmit, formState } =
    useFormContext<DeepReadonly<ContractForEdit>>();
  const onInvalid = async (): Promise<void> => {
    const errorValues = Object.values(formState.errors);
    if (errorValues.length > 0) {
      const inputRef: HTMLInputElement | null =
        errorValues[0].ref instanceof HTMLInputElement
          ? errorValues[0].ref
          : null;
      if (inputRef !== null) {
        inputRef.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };
  const onClickSave = (): void => {
    handleSubmit(async () => {
      const response = await create(convertParams(getValues()));
      if (isSuccess(response)) {
        if (response.data?.id != null) {
          await router.push(PATH(response.data.id));
        }
        toast.success('登録しました');
        return;
      }
      toast.error(response.message);
    }, onInvalid)();
  };
  const onClickUpdate = (): void => {
    handleSubmit(async () => {
      const response = await update(Number(id), convertParams(getValues()));
      if (isSuccess(response)) {
        if (response.data?.id != null) {
          await router.push(PATH(response.data.id));
        }
        toast.success('編集しました');
        return;
      }
      toast.error(response.message);
    }, onInvalid)();
  };
  const onClickRenewal = (): void => {
    handleSubmit(async () => {
      const response = await renewal(Number(id), convertParams(getValues()));
      if (isSuccess(response)) {
        if (response.data?.id != null) {
          await router.push(PATH(response.data.id));
        }
        toast.success('更新しました');
        return;
      }
      toast.error(response.message);
    }, onInvalid)();
  };
  const onClickUpdateArchive = (): void => {
    handleSubmit(async () => {
      const response = await editArchive(
        Number(id),
        convertParams(getValues())
      );
      if (isSuccess(response)) {
        if (response.data?.id != null) {
          await router.push(PATH(response.data.id));
        }
        toast.success('編集しました');
        return;
      }
      toast.error(response.message);
    }, onInvalid)();
  };
  return (
    <div className={Styles.FooterArea}>
      <div className={Styles.Buttons}>
        <span className={Styles.SaveButton}>
          <Button
            onClick={
              (editorMode === 'new' && onClickSave) ||
              (editorMode === 'edit' && onClickUpdate) ||
              (editorMode === 'editArchive' && onClickUpdateArchive) ||
              onClickRenewal
            }
            variant="primary"
            width="fill"
          >
            {(editorMode === 'new' && '作成') ||
              (editorMode === 'edit' && '修正') ||
              (editorMode === 'editArchive' && '修正') ||
              '更新'}
          </Button>
        </span>
      </div>
    </div>
  );
};
