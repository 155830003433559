import { ContractForEditParams } from '~/models/contract';
import { YkResponse } from '~/models/api';
import { postRequest, putRequest } from '~/utils/api/apiHandler';

const paths = {
  create: '/contracts',
  update: '/contracts/:id',
  renewal: '/contracts/:id/renewals',
  updateArchive: '/contracts/:id/archive',
  updateAuthId: '/contracts/:id/auth_id',
  editArchive: '/contracts/:id/update_archived'
};

export const create = async (
  contract: DeepReadonly<ContractForEditParams>
): Promise<YkResponse<{ id: number }>> =>
  postRequest(paths.create, { contract });

export const update = async (
  contractId: number,
  contract: DeepReadonly<ContractForEditParams>
): Promise<YkResponse<{ id: number }>> =>
  putRequest(paths.update.replace(':id', String(contractId)), { contract });

export const renewal = async (
  contractId: number,
  contract: DeepReadonly<ContractForEditParams>
): Promise<YkResponse<{ id: number }>> =>
  postRequest(paths.renewal.replace(':id', String(contractId)), { contract });

export const updateArchive = async (
  contractId: number
): Promise<YkResponse<{ id: number }>> =>
  putRequest(paths.updateArchive.replace(':id', String(contractId)), {
    archived: true
  });

export const updateUnarchive = async (
  contractId: number
): Promise<YkResponse<{ id: number }>> =>
  putRequest(paths.updateArchive.replace(':id', String(contractId)), {
    archived: false
  });

export const updateAuthId = async (
  contractId: number,
  newAuthId: string
): Promise<YkResponse<{ id: number }>> =>
  putRequest(paths.updateAuthId.replace(':id', String(contractId)), {
    authId: newAuthId
  });

export const editArchive = async (
  contractId: number,
  contract: DeepReadonly<ContractForEditParams>
): Promise<YkResponse<{ id: number }>> =>
  putRequest(paths.editArchive.replace(':id', String(contractId)), {
    contract
  });
