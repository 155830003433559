import { Property } from '~/models/property';
import { RemoteProperty } from '~/models/remoteProperty';

const getSeisanKanrikunOrigin = (): string => {
  if (typeof window === 'undefined') {
    return '';
  }
  switch (window.location.hostname) {
    case 'localhost':
      return 'https://staging.seisan-kanrikun.itandi.co.jp';
    case 'stg.yanushi-kanrikun.itandibb.com':
      return 'https://staging.seisan-kanrikun.itandi.co.jp';
    case 'yanushi-kanrikun.itandibb.com':
      return 'https://seisan-kanrikun.itandibb.com';
    default:
      throw new Error('Unknown Origin');
  }
};

export const seisanKanrikunOwnerNew = `${getSeisanKanrikunOrigin()}/owners/new`;

export const seisanKanrikunContractNew = `${getSeisanKanrikunOrigin()}/property_managements/new`;

export const buildSeisanKanrikunContractUrl = (id: number): string =>
  `${getSeisanKanrikunOrigin()}/sessions/redirect/yk/property_managements/${id}`;

export const buildSeisanKanrikunOwnerUrl = (id: number): string =>
  `${getSeisanKanrikunOrigin()}/sessions/redirect/yk/owners/${id}`;

const getBukkenKanrikunOrigin = (): string => {
  if (typeof window === 'undefined') {
    return '';
  }
  switch (window.location.hostname) {
    case 'localhost':
      return 'https://staging.bukken-kanrikun.com';
    case 'stg.yanushi-kanrikun.itandibb.com':
      return 'https://staging.bukken-kanrikun.com';
    case 'yanushi-kanrikun.itandibb.com':
      return 'https://bukken-kanrikun.com';
    default:
      throw new Error('Unknown Origin');
  }
};

// 建物一括で貸し出しのBKKのurl
const buildBukkenKanrikunRentBuildingUrl = (id: number): string =>
  `${getBukkenKanrikunOrigin()}/rent_buildings/${id}/edit`;

// 部屋ごとに貸し出しの建物のBKKのurl
const buildBukkenKanrikunRentRoomBuildingsUrl = (id: number): string =>
  `${getBukkenKanrikunOrigin()}/rent_room_buildings/${id}/edit`;

const buildBukkenKanrikunRentRoomUrl = (id: number): string =>
  `${getBukkenKanrikunOrigin()}/rent_rooms/${id}/edit`;

const buildBukkenKanrikunRentParkingUrl = (id: number): string =>
  `${getBukkenKanrikunOrigin()}/rent_parkings/${id}/edit`;

const buildBukkenKanrikunRentParkingSpaceUrl = (id: number): string =>
  `${getBukkenKanrikunOrigin()}/rent_parking_spaces/${id}/edit`;

export const buildBukkenKanrikunUrl = (
  property: DeepReadonly<Property>,
  remoteProperty: DeepReadonly<RemoteProperty>
): string => {
  if (property.propertyType === 'building' && property.wholeRentBuilding) {
    return buildBukkenKanrikunRentBuildingUrl(
      remoteProperty.kensakukunPropertyId
    );
  }
  if (property.propertyType === 'building' && !property.wholeRentBuilding) {
    return buildBukkenKanrikunRentRoomBuildingsUrl(
      remoteProperty.kensakukunPropertyId
    );
  }
  if (property.propertyType === 'room') {
    return buildBukkenKanrikunRentRoomUrl(remoteProperty.kensakukunPropertyId);
  }
  if (property.propertyType === 'parking') {
    return buildBukkenKanrikunRentParkingUrl(
      remoteProperty.kensakukunPropertyId
    );
  }
  if (property.propertyType === 'parking_space') {
    return buildBukkenKanrikunRentParkingSpaceUrl(
      remoteProperty.kensakukunPropertyId
    );
  }
  return '';
};

const getItandiAccountsOrigin = (): string => {
  if (typeof window === 'undefined') {
    return '';
  }
  switch (window.location.hostname) {
    case 'localhost':
      return 'https://staging.itandi-accounts.com';
    case 'stg.yanushi-kanrikun.itandibb.com':
      return 'https://staging.itandi-accounts.com';
    case 'yanushi-kanrikun.itandibb.com':
      return 'https://itandi-accounts.com';
    default:
      throw new Error('Unknown Origin');
  }
};

export const itandiAccountUsersIndexUrl = `${getItandiAccountsOrigin()}/account_management/users`;

export const buildItandiAccountUsersShowUrl = (authUserId: number): string =>
  `${getItandiAccountsOrigin()}/account_management/users/${authUserId}`;
